Vue.component("navigation-list-item", {
    data: function () {
        return {
            selectOpen: false,
            active: false,
            subMenu: false,
            overlayMenu: false,
            overlaySubmenu: false,
        }
    },
    watch: {
        active(val) {
            if(val === true) {
                bodyScrollLock.enableBodyScroll(this.$parent.$refs.scrollLock);
                bodyScrollLock.disableBodyScroll(this.$refs.scrollLock);
            }
        },
        menuExpanded(newValue) {
            if(newValue == false) {
                this.active = false;
                bodyScrollLock.clearAllBodyScrollLocks();
            }
        }
    },
    props: {
        menuExpanded: Boolean,
        showTopbar: Boolean,
        level: Number,
    },
    mounted() {
        bodyScrollLock.clearAllBodyScrollLocks();

        if (this.level == 1) {
            this.$parent.subMenu = true
        };

        if(this.level > 1) {
            this.overlayMenu = true;
            this.$parent.overlayMenu = true;
            this.$parent.$parent.overlayMenu = true;
        };

        if(this.overlayMenu == true) {
            if(this.level > 1) this.$parent.overlaySubmenu = true;
            this.subMenu = false;
            this.$children.forEach(function (child) {
                child.overlayMenu = true;
            });
            if (window.innerWidth < 768) {
                this.overlayMenu = false;
            }
        };

        document.body.addEventListener('keyup', e => {
            if (e.keyCode === 27) {
                this.closeNav()
            }
        })
    },
    updated() {
        if (window.innerWidth >= 768) {
            let level2 = document.querySelector(".level-2-visible");
            let level3 = document.querySelector(".level-3-visible");
            let level4 = document.querySelector(".level-4-visible");
            let level5 = document.querySelector(".level-5-visible");

            if (level2 != null) {
                document.querySelector(".overlay-menu-1__columns").classList.remove("overlay-menu-1__columns--three");
                document.querySelector(".overlay-menu-1__columns").classList.remove("overlay-menu-1__columns--four");
                document.querySelector(".overlay-menu-1__columns").classList.remove("overlay-menu-1__columns--five");
                document.querySelector(".overlay-menu-1__columns").classList.toggle("overlay-menu-1__columns--two");
            }
            if (level3 != null) {
                document.querySelector(".overlay-menu-1__columns").classList.remove("overlay-menu-1__columns--two");
                document.querySelector(".overlay-menu-1__columns").classList.remove("overlay-menu-1__columns--four");
                document.querySelector(".overlay-menu-1__columns").classList.remove("overlay-menu-1__columns--five");
                document.querySelector(".overlay-menu-1__columns").classList.toggle("overlay-menu-1__columns--three")
            }
            if (level4 != null) {
                document.querySelector(".overlay-menu-1__columns").classList.remove("overlay-menu-1__columns--two");
                document.querySelector(".overlay-menu-1__columns").classList.remove("overlay-menu-1__columns--three");
                document.querySelector(".overlay-menu-1__columns").classList.remove("overlay-menu-1__columns--five");
                document.querySelector(".overlay-menu-1__columns").classList.toggle("overlay-menu-1__columns--four")
            }
            if (level5 != null) {
                document.querySelector(".overlay-menu-1__columns").classList.remove("overlay-menu-1__columns--two");
                document.querySelector(".overlay-menu-1__columns").classList.remove("overlay-menu-1__columns--three");
                document.querySelector(".overlay-menu-1__columns").classList.remove("overlay-menu-1__columns--four");
                document.querySelector(".overlay-menu-1__columns").classList.toggle("overlay-menu-1__columns--five")
            }

        }
    },
    methods: {
        openSub(event) {
            if (window.innerWidth < 768) {
                event.preventDefault();
                event.stopPropagation();
                this.active = true;
                return false;
            };
        },
        closeNav() {
            if (window.innerWidth < 768) {
                this.active = false;
                console.log('CloseNav')
            }
            else {
                bodyScrollLock.clearAllBodyScrollLocks();
                this.active = false;
            }
        },
        closeSub() {
            this.active = false;

            this.$parent.$children.forEach(function (sibling) {
                sibling.active = false;

                sibling.$children.forEach( function (child) {
                    child.active = false;

                    child.$children.forEach( function (child2) {
                        child2.active = false;

                        child2.$children.forEach( function (child3) {
                            child3.active = false;
                        })
                    })
                })
            })
        },
        listItemClick(event) {
            if (window.innerWidth < 768) {
                this.openSub(event);
                console.log('ListItemClick');
            }
            else {
                if(this.overlayMenu == true) {
                    event.preventDefault();
                    event.stopPropagation();

                    this.$parent.$children.forEach(function (sibling) {
                        sibling.active = false;

                        sibling.$children.forEach( function (child) {
                            child.active = false;

                            child.$children.forEach( function (child2) {
                                child2.active = false;

                                child2.$children.forEach( function (child3) {
                                    child3.active = false;
                                })
                            })
                        })
                    })

                    this.active = true;
                    return false;
                }

                if(this.subMenu == true) {
                    event.preventDefault();
                    event.stopPropagation();
                }
            }
        }
    }
});
