Vue.component('form-component', {
    data: function () {
        return {
            hasCaptcha: false,
            captchaExecuted: false,
            inputs: []
        }
    },
    components: {
        'vue-recaptcha': VueRecaptcha
    },
    mounted() {
        this.dataFilled();
        this.inputFileName();
    },

    updated() {
        this.dataFilled();
    },
    methods: {
        submit: function () {
            let form = this.$el;

            this.$validator.validateAll().then(result => {
                let form = this.$el;
                let analytics = null;

                if(form.dataset.analytics){
                    analytics = form.dataset.analytics;
                }

                if (result && analytics) {
                    if (!this.hasCaptcha || this.captchaExecuted) {
                        this.$root.submitForm(form, analytics);
                        this.resetCaptcha();
                    } else {
                        this.$refs.recaptcha.execute();
                    }
                } else if (result) {
                    this.$root.submitForm(form);
                    this.resetCaptcha();
                }
            });
        },
        dataFilled () {
            this.inputs = this.$el.querySelectorAll('.form__input');

            for (let i = 0, input; input = this.inputs[i]; i++) {
                input.addEventListener('input', e => {
                    const value = e.target.value.trim();

                    if (value) {
                        e.target.dataset.state = 'filled'
                    } else {
                        e.target.dataset.state = ''
                    }
                })
            }
        },
        onCaptchaVerified: function () {
            this.captchaExecuted = true;
            this.submit();
        },
        resetCaptcha: function() {
            this.$refs.recaptcha.reset();
            this.captchaExecuted = false;
        },
        inputFileName () {
            let inputs = document.querySelectorAll( '.file-input__input' );
            Array.prototype.forEach.call( inputs, function( input )
            {
                var label	 = input.nextElementSibling,
                    labelVal = label.innerHTML;

                input.addEventListener( 'change', function( e )
                {
                    var fileName = '';
                    if( this.files && this.files.length > 1 )
                        fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
                    else
                        fileName = e.target.value.split( '\\' ).pop();

                    if( fileName )
                        label.innerHTML = fileName;
                    else
                        label.innerHTML = labelVal;
                });
            });
        }
    },
});
