Vue.component("product-card", {
    data: function () {
        return {
            isVisible: false,
        }
    },
    methods: {
        hasScrolled() {
            let that = this;
            let current = window.pageYOffset;
            document.addEventListener('scroll', function () {
                that.isVisible = true;
                new Blazy();
            })
        }
    },
    mounted() {
        this.hasScrolled();
    }
});
