Vue.component("language-select", {
    data: function () {
        return {
            selectOpen: false,
        }
    },
    methods: {
        close() {
            this.selectOpen = false;
        }
    }
});