Vue.component("header-component", {
    data: function () {
        return {
            scrolled: false,
            menuExpanded: false,
            overlayMenu: false,
            search: false,
            showTopbar: true,
            lastScrollPosition: 0,
        }
    },
    watch: {
        menuExpanded(newValue) {
            if(newValue) {
                bodyScrollLock.disableBodyScroll(this.$refs.scrollLock);
            } else {
                bodyScrollLock.enableBodyScroll(this.$refs.scrollLock);
                this.closeOverlayMenu();
            }
        },
        showTopbar(val) {
            if(val) {
                this.search = false;
            } else {
                this.search = false;
            }
        }
    },
    mounted() {
        bodyScrollLock.clearAllBodyScrollLocks();

        let that = this;

        window.addEventListener('scroll', function () {
            that.watchScrollPosition();
        });

        this.watchScrollPosition();

        window.addEventListener("resize", function() {
            if (window.innerWidth > 768) {
                that.menuExpanded = false;
            }
        });

        window.addEventListener('scroll', this.onScroll)
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.onScroll);
    },
    methods: {
        watchScrollPosition() {
            let that = this;
            const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);

            if (scrollTop > 50) {
                that.scrolled = true;
            } else {
                that.scrolled = false;
            }
        },
        onScroll () {
            const currentScrollPosition = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);

            if (currentScrollPosition < 0) {
                return
            }

            if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 100) {
                return
            }
            this.showTopbar = currentScrollPosition < this.lastScrollPosition;
            this.lastScrollPosition = currentScrollPosition
        },
        closeOverlayMenu() {
            let navClose = document.querySelector('.nav-close');
            let activeMainNav = document.querySelectorAll('.nav-item__active');
            let activeSubNav = document.querySelectorAll('.nav-item__has-children--active');
            let activeNavLink = document.querySelectorAll('.nav-link--active');
            activeMainNav.forEach(item => item.classList.remove('nav-item__active'));
            activeSubNav.forEach(item => item.classList.replace('nav-item__has-children--active', 'nav-item__has-children'));
            activeNavLink.forEach(item => item.classList.remove('nav-link--active'));
            navClose.classList.remove('nav-close__active');
            document.body.setAttribute("style", "");
        },
    },
});
